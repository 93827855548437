{
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54619445615192,
              13.727115851471551
            ],
            [
              100.54620225851292,
              13.727165726178912
            ],
            [
              100.54622514543853,
              13.727164214824144
            ],
            [
              100.54621786323493,
              13.727111317407244
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54622618575333,
              13.727163711039221
            ],
            [
              100.54624855252153,
              13.727159680759838
            ],
            [
              100.54624075016052,
              13.727107287127861
            ],
            [
              100.54621786323493,
              13.727112324977091
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54624959283633,
              13.727159680759838
            ],
            [
              100.54627039913233,
              13.727155146695532
            ],
            [
              100.54626155645653,
              13.727103760633401
            ],
            [
              100.54624127031792,
              13.727106279558017
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54643112776893,
              13.726041782016033
            ],
            [
              100.54645349453713,
              13.726057903133563
            ],
            [
              100.54650655059193,
              13.72603775173665
            ],
            [
              100.54649510712913,
              13.725967725632373
            ],
            [
              100.54642384556533,
              13.725994930018207
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54651799405472,
              13.72603271388742
            ],
            [
              100.54658613467413,
              13.726005005716663
            ],
            [
              100.54658405404453,
              13.725988884599133
            ],
            [
              100.54659393703513,
              13.725985358104673
            ],
            [
              100.54658457420193,
              13.725928934193314
            ],
            [
              100.54650655059193,
              13.725961680213299
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54659757813693,
              13.726000471652359
            ],
            [
              100.54670212977433,
              13.725955634794225
            ],
            [
              100.54667404127473,
              13.725892157893945
            ],
            [
              100.54658561451673,
              13.725928430408391
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54670212977433,
              13.725955634794225
            ],
            [
              100.54679263716193,
              13.725918858494858
            ],
            [
              100.54678171385653,
              13.725848328605657
            ],
            [
              100.54667404127473,
              13.725891654109022
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54680668141174,
              13.725913316860705
            ],
            [
              100.54679419763414,
              13.725841779401661
            ],
            [
              100.54686805998493,
              13.725812056091213
            ],
            [
              100.54689666864194,
              13.725876036776414
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54689718879934,
              13.725875532991491
            ],
            [
              100.54699029697395,
              13.72583623776751
            ],
            [
              100.54696220847434,
              13.72577276086723
            ],
            [
              100.54686858014233,
              13.725812559876134
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54699133728875,
              13.725837749122277
            ],
            [
              100.54708080436154,
              13.725801476607833
            ],
            [
              100.54705271586194,
              13.725736488352785
            ],
            [
              100.54696272863174,
              13.725773264652153
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54708080436154,
              13.72580097282291
            ],
            [
              100.54717599316574,
              13.725761173814004
            ],
            [
              100.54714738450875,
              13.725697193128802
            ],
            [
              100.54705271586194,
              13.725736488352785
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54717599316574,
              13.725761677598927
            ],
            [
              100.54725297646094,
              13.725729435363865
            ],
            [
              100.54724153299814,
              13.725658401689742
            ],
            [
              100.54714634419393,
              13.725697193128802
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54706519963953,
              13.725967725632373
            ],
            [
              100.54712605805534,
              13.725958657503762
            ],
            [
              100.54712033632394,
              13.725921377419471
            ],
            [
              100.54705895775074,
              13.725932460687774
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54715726749934,
              13.725952612084688
            ],
            [
              100.54721812591514,
              13.725943543956076
            ],
            [
              100.54721136386894,
              13.725906767656708
            ],
            [
              100.54715102561055,
              13.725916339570242
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54705167554714,
              13.725892157893945
            ],
            [
              100.54711305412035,
              13.725882082195488
            ],
            [
              100.54710629207413,
              13.725846817250888
            ],
            [
              100.54704543365834,
              13.725855381594577
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54714322324954,
              13.725877044346261
            ],
            [
              100.54720616229494,
              13.725866968647804
            ],
            [
              100.54719992040614,
              13.725831199918281
            ],
            [
              100.54713750151814,
              13.725841275616737
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54644361154654,
              13.727000484724217
            ],
            [
              100.54653984066553,
              13.726985874961455
            ],
            [
              100.54657625168353,
              13.727197464629052
            ],
            [
              100.54689458801234,
              13.727145574781998
            ],
            [
              100.54693307965994,
              13.727104264418324
            ],
            [
              100.54694348280793,
              13.727102249278634
            ],
            [
              100.54685037463334,
              13.726560680486569
            ],
            [
              100.54644517201874,
              13.726627180096385
            ],
            [
              100.54640720052854,
              13.726670001814828
            ],
            [
              100.54642332540793,
              13.726768743659706
            ],
            [
              100.54639731753792,
              13.726773277724012
            ],
            [
              100.54641656336173,
              13.726886125546731
            ],
            [
              100.54642436572273,
              13.726884110407038
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54694348280793,
              13.727101745493711
            ],
            [
              100.54718431568413,
              13.727062450269727
            ],
            [
              100.54723529110935,
              13.727090158440484
            ],
            [
              100.54741214462534,
              13.727061442699883
            ],
            [
              100.54734452416335,
              13.726669498029905
            ],
            [
              100.54732163723774,
              13.726673024524365
            ],
            [
              100.54728990763634,
              13.726490150597369
            ],
            [
              100.54685089479074,
              13.726561184271491
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54603528798752,
              13.726616600613005
            ],
            [
              100.54627508054892,
              13.726577305389023
            ],
            [
              100.54624699204933,
              13.726412567719251
            ],
            [
              100.54628028212292,
              13.726407026085099
            ],
            [
              100.54622254465153,
              13.726074024251094
            ],
            [
              100.54621266166093,
              13.726076039390787
            ],
            [
              100.54626883866013,
              13.726399973096179
            ],
            [
              100.54623658890132,
              13.726405010945408
            ],
            [
              100.54626519755833,
              13.726570252400103
            ],
            [
              100.54603372751532,
              13.726608036269317
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54622306480893,
              13.726074024251094
            ],
            [
              100.54634374132573,
              13.72602364575881
            ],
            [
              100.54634010022393,
              13.726014577630199
            ],
            [
              100.54621838339233,
              13.72606596369233
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54642124477833,
              13.725991403523747
            ],
            [
              100.54778873858295,
              13.725428171979999
            ],
            [
              100.54778405716635,
              13.725420615206158
            ],
            [
              100.54641656336173,
              13.725984350534827
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54778769826815,
              13.725428171979999
            ],
            [
              100.54830161377936,
              13.72534504746773
            ],
            [
              100.54833854495476,
              13.725559156059941
            ],
            [
              100.54837131487096,
              13.72555361442579
            ],
            [
              100.54846286257336,
              13.726083092379707
            ],
            [
              100.54847118509176,
              13.726081581024937
            ],
            [
              100.54837807691716,
              13.725543538727333
            ],
            [
              100.54834686747316,
              13.725548576576561
            ],
            [
              100.54830993629776,
              13.725335475554195
            ],
            [
              100.54778613779595,
              13.725420111421235
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54627976196552,
              13.726580831883483
            ],
            [
              100.54631253188173,
              13.726575794034254
            ],
            [
              100.54634530179793,
              13.726599975710551
            ],
            [
              100.54637547092713,
              13.726562191841337
            ],
            [
              100.54637130966793,
              13.72653801016504
            ],
            [
              100.54634270101093,
              13.726515339843513
            ],
            [
              100.54635778557552,
              13.726495188446599
            ],
            [
              100.54634842274233,
              13.726435238040779
            ],
            [
              100.54638015234373,
              13.726430200191551
            ],
            [
              100.54642124477833,
              13.726375791419882
            ],
            [
              100.54641500288953,
              13.72634002269036
            ],
            [
              100.54627560070632,
              13.726362189226965
            ],
            [
              100.54628392322472,
              13.726411560149405
            ],
            [
              100.54625167346593,
              13.726416597998632
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54650811106413,
              13.726466976490919
            ],
            [
              100.54655908648934,
              13.726505264145056
            ],
            [
              100.54659653782213,
              13.726457404577385
            ],
            [
              100.54658301372973,
              13.726377302774651
            ],
            [
              100.54649510712913,
              13.726391408752491
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54649510712913,
              13.726391912537414
            ],
            [
              100.54648418382374,
              13.726327931852211
            ],
            [
              100.54656740900774,
              13.726314833444217
            ],
            [
              100.54657000979474,
              13.726329946991903
            ],
            [
              100.54657885247053,
              13.726328435637134
            ],
            [
              100.54658145325753,
              13.726343045399897
            ],
            [
              100.54662306584953,
              13.726335992410977
            ],
            [
              100.54662930773833,
              13.726369746000808
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54648470398114,
              13.726327931852211
            ],
            [
              100.54648158303674,
              13.726310803164834
            ],
            [
              100.54651643358253,
              13.726244303555019
            ],
            [
              100.54652943751754,
              13.726250852759016
            ],
            [
              100.54659913860914,
              13.726238258135943
            ],
            [
              100.54661370301633,
              13.726321886433137
            ],
            [
              100.54657000979474,
              13.726328939422057
            ],
            [
              100.54656740900774,
              13.726314833444217
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54652631657314,
              13.726226671082719
            ],
            [
              100.54653619956373,
              13.726232716501793
            ],
            [
              100.54659653782213,
              13.726223144588259
            ],
            [
              100.54658873546113,
              13.726178811515048
            ],
            [
              100.54662202553473,
              13.726173269880896
            ],
            [
              100.54661786427553,
              13.726147576849831
            ],
            [
              100.54656220743374,
              13.726156644978442
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54669380725593,
              13.726163697967362
            ],
            [
              100.54683476991134,
              13.726141027645834
            ],
            [
              100.54682488692073,
              13.726083092379707
            ],
            [
              100.54681604424494,
              13.726084099949551
            ],
            [
              100.54680928219874,
              13.726045308510493
            ],
            [
              100.54671721433894,
              13.726060422058177
            ],
            [
              100.54670421040393,
              13.726067475047097
            ],
            [
              100.54669484757073,
              13.726079062100324
            ],
            [
              100.54669016615414,
              13.726091152938471
            ],
            [
              100.54668600489494,
              13.726119868679074
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54655856633193,
              13.726505264145056
            ],
            [
              100.54660173939614,
              13.72653801016504
            ],
            [
              100.54660694097014,
              13.726539017734886
            ],
            [
              100.54661162238674,
              13.726540025304733
            ],
            [
              100.54669380725593,
              13.726526423111814
            ],
            [
              100.54667716221914,
              13.726431207761396
            ],
            [
              100.54671929496854,
              13.726424154772476
            ],
            [
              100.54670785150573,
              13.726357151377737
            ],
            [
              100.54658249357233,
              13.726377302774651
            ],
            [
              100.54659601766473,
              13.726456900792462
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54669380725593,
              13.726525919326893
            ],
            [
              100.54683945132794,
              13.726502241435519
            ],
            [
              100.54682280629113,
              13.726406522300175
            ],
            [
              100.54667768237654,
              13.726430200191551
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54687430187374,
              13.726497203586289
            ],
            [
              100.54721448481334,
              13.726441787244776
            ],
            [
              100.54724309347034,
              13.726379821699265
            ],
            [
              100.54723633142415,
              13.72634002269036
            ],
            [
              100.54685661652214,
              13.726401484450948
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54673125858874,
              13.726401484450948
            ],
            [
              100.54682124581893,
              13.726386874688185
            ],
            [
              100.54679003637493,
              13.726206519685805
            ],
            [
              100.54670056930213,
              13.726221129448566
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54682176597633,
              13.726386874688185
            ],
            [
              100.54687846313294,
              13.726377806559574
            ],
            [
              100.54684725368894,
              13.726197451557193
            ],
            [
              100.54679055653233,
              13.726206519685805
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54711357427775,
              13.726086115089244
            ],
            [
              100.54720980339674,
              13.726070497756634
            ],
            [
              100.54720460182274,
              13.726042285800956
            ],
            [
              100.54710993317595,
              13.72605739934864
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54683372959654,
              13.726130951947377
            ],
            [
              100.54690187021593,
              13.726119868679074
            ],
            [
              100.54689250738274,
              13.726061933412947
            ],
            [
              100.54682228613373,
              13.726072512896327
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54682228613373,
              13.72607150532648
            ],
            [
              100.54690603147513,
              13.726057903133563
            ],
            [
              100.54690135005853,
              13.72603271388742
            ],
            [
              100.54681968534673,
              13.726045812295416
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54690447100293,
              13.72612792923784
            ],
            [
              100.54697833335374,
              13.726115334614768
            ],
            [
              100.54697261162234,
              13.726085107519397
            ],
            [
              100.54689926942893,
              13.7260961907877
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54697781319634,
              13.726115334614768
            ],
            [
              100.54705323601934,
              13.72610324377662
            ],
            [
              100.54704127239914,
              13.726033217672343
            ],
            [
              100.54702306689013,
              13.726035736596957
            ],
            [
              100.54701734515874,
              13.726012058705583
            ],
            [
              100.54690030974373,
              13.726032210102497
            ],
            [
              100.54690499116033,
              13.726059414488333
            ],
            [
              100.54689302754014,
              13.726061933412947
            ],
            [
              100.54689926942893,
              13.7260961907877
            ],
            [
              100.54697261162234,
              13.726085107519397
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54705323601934,
              13.726095687002777
            ],
            [
              100.54708808656514,
              13.726089641583704
            ],
            [
              100.54707716325974,
              13.726022638188963
            ],
            [
              100.54704127239914,
              13.726028179823116
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54687794297554,
              13.726377806559574
            ],
            [
              100.54725557724794,
              13.72631533722914
            ],
            [
              100.54721916622994,
              13.726102739991697
            ],
            [
              100.54684049164274,
              13.726164201752285
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54662878758093,
              13.726370249785731
            ],
            [
              100.54667352111733,
              13.726362189226965
            ],
            [
              100.54667144048773,
              13.726347579464203
            ],
            [
              100.54670577087613,
              13.726342037830051
            ],
            [
              100.54668496458014,
              13.726224655943026
            ],
            [
              100.54659861845174,
              13.726238761920866
            ],
            [
              100.54661370301633,
              13.726321886433137
            ],
            [
              100.54657885247053,
              13.726327931852211
            ],
            [
              100.54658197341493,
              13.72634354918482
            ],
            [
              100.54662358600693,
              13.7263364961959
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54663294884013,
              13.726232212716869
            ],
            [
              100.54665947686753,
              13.726227678652565
            ],
            [
              100.54664595277514,
              13.726149088204599
            ],
            [
              100.54661942474773,
              13.726153622268905
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54627612086372,
              13.726362189226965
            ],
            [
              100.54641500288953,
              13.726339518905437
            ],
            [
              100.54640147879712,
              13.72626042467255
            ],
            [
              100.54633229786293,
              13.726271507940853
            ],
            [
              100.54632501565934,
              13.726230701362102
            ],
            [
              100.54625479441033,
              13.726242288415326
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54638431360293,
              13.726249341404246
            ],
            [
              100.54640043848232,
              13.726246822479633
            ],
            [
              100.54637443061233,
              13.7260961907877
            ],
            [
              100.54636090651992,
              13.726098205927391
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54638535391773,
              13.726261936027319
            ],
            [
              100.54637547092713,
              13.726205512115959
            ],
            [
              100.54632033424272,
              13.726214580244569
            ],
            [
              100.54630160857633,
              13.72610677027108
            ],
            [
              100.54630576983553,
              13.726135486011682
            ],
            [
              100.54623866953092,
              13.726145561710139
            ],
            [
              100.54625531456773,
              13.726242288415326
            ],
            [
              100.54632449550194,
              13.726230197577179
            ],
            [
              100.54633229786293,
              13.726271507940853
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54726129897934,
              13.726335992410977
            ],
            [
              100.54726598039595,
              13.726334984841131
            ],
            [
              100.54726858118295,
              13.726348587034048
            ],
            [
              100.54729875031214,
              13.72634354918482
            ],
            [
              100.54731331471935,
              13.726323397787906
            ],
            [
              100.54725297646094,
              13.725973771051448
            ],
            [
              100.54719627930435,
              13.725982839180059
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              100.54736793124634,
              13.726394431462028
            ],
            [
              100.54747404335595,
              13.726376798989728
            ],
            [
              100.54747664414295,
              13.726389897397722
            ],
            [
              100.54784907684135,
              13.72632944320698
            ],
            [
              100.54782618991575,
              13.726196947772269
            ],
            [
              100.54784959699875,
              13.726192917492888
            ],
            [
              100.54782150849916,
              13.726032210102497
            ],
            [
              100.54776741212955,
              13.726040774446187
            ],
            [
              100.54774140425955,
              13.725889135184408
            ],
            [
              100.54780382314755,
              13.725879059485951
            ],
            [
              100.54778197653675,
              13.725754120825084
            ],
            [
              100.54796923320075,
              13.725723893729713
            ],
            [
              100.54794686643255,
              13.725594421004541
            ],
            [
              100.54797391461736,
              13.725590390725158
            ],
            [
              100.54796611225635,
              13.7255450500821
            ],
            [
              100.54747144256895,
              13.725625151884835
            ],
            [
              100.54748496666134,
              13.725705253687568
            ],
            [
              100.54742410824555,
              13.725714825601102
            ],
            [
              100.54741110431054,
              13.725639257862675
            ],
            [
              100.54735128620955,
              13.725649333561131
            ],
            [
              100.54734556447815,
              13.725616083756224
            ],
            [
              100.54724153299814,
              13.725657897904821
            ],
            [
              100.54726754086815,
              13.725811048521367
            ],
            [
              100.54733308070054,
              13.725800469037987
            ],
            [
              100.54735284668175,
              13.725916339570242
            ],
            [
              100.54728834716414,
              13.725926919053622
            ]
          ]
        ]
      }
    }
  ]
}