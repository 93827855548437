import b1_2Geojson from "./asseets/b1_2.json";
import "./style.css";
import Keyboard from "./js/keyboard";
import shopIcon from "./asseets/images/shop.png";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYW5na2FybiIsImEiOiJjamNsZmZhdHAwOW9lMnluM3JvdnZ0ZDRqIn0.e2XtXpUTNRwj__dWvXCJQQ";
const map = new mapboxgl.Map({
  container: "map", // container ID
  style: "mapbox://styles/mapbox/light-v11", // style URL
  center: [100.546295, 13.726078], // starting position [lng, lat]
  zoom: 17, // starting zoom
  attributionControl: false,
});

const mockSearchResult = [
  {
    title: "Shop A",
    logo: shopIcon,
  },
  {
    title: "Shop B",
    logo: shopIcon,
  },
];

const searchParams = new URLSearchParams(window.location.search);
const kioskParams = searchParams.get("kiosk");
const inputSearch = document.querySelector("#inputSearch");

// check kiosk mode
if (kioskParams) {
  document.querySelector("#inputSearch").setAttribute("readonly", "");
  inputSearch.addEventListener("click", () => {
    document.getElementById("virKeyboard").style.display = "block";
    document.querySelector(".bottomAction").style.bottom = "170px";
  });
  Keyboard(
    (input) => {
      inputSearch.value = input;
      console.log("Input changed", input);
      searchShop(input);
    },
    () => {
      document.getElementById("virKeyboard").style.display = "none";
      document.querySelector(".bottomAction").style.bottom = "0";
      inputSearch.addEventListener("click", () => {
        document.getElementById("virKeyboard").style.display = "block";
        document.querySelector(".bottomAction").style.bottom = "170px";
      });
    }
  );
} else {
  inputSearch.addEventListener("input", (e) => {
    console.log("inputSearch", e.target.value);
    searchShop(e.target.value);
  });
}

function searchShop(query) {
  if (query) {
    const shops = mockSearchResult;
    document.getElementById("searchResult").style.display = "block";
    let render = "";
    shops.forEach((s) => {
      render += `<div class="searchItem">
    <img class="shopIcon" src="${s.logo}">
    <div>${s.title}</div>
    </div>`;
    });

    document.getElementById("searchResult").innerHTML = render;
  } else {
    document.getElementById("searchResult").style.display = "none";
  }
}

// example fetch geojson
async function getCustomGeojson() {
  fetch("/b1_2_custom.json")
    .then((r) => r.json())
    .then((json) => {
      map.getSource("floorplan").setData(json);
    });
}

document.querySelector("#customGeojson").onclick = () => {
  getCustomGeojson();
};

map.on("load", () => {
  // Add a data source containing GeoJSON data.
  map.addSource("floorplan", {
    type: "geojson",
    data: b1_2Geojson,
  });

  map.addLayer({
    id: "room-extrusion",
    type: "fill-extrusion",
    source: "floorplan",
    paint: {
      // Get the `fill-extrusion-color` from the source `color` property.
      "fill-extrusion-color": ["get", "color"],

      // Get `fill-extrusion-height` from the source `height` property.
      "fill-extrusion-height": ["get", "height"],

      // Get `fill-extrusion-base` from the source `base_height` property.
      "fill-extrusion-base": ["get", "base_height"],

      // Make extrusions slightly opaque to see through indoor walls.
      "fill-extrusion-opacity": 0.5,
    },
  });
});
